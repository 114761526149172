jQuery(document).ready(function() {
  // Note: This example requires that you consent to location sharing when
  // prompted by your browser. If you see the error "The Geolocation service
  // failed.", it means you probably did not give permission for the browser to
  // locate you.

  if (jQuery(".rep-locator").length > 0) {
    //console.log("Locating user.");
    //detect_user_location();
  }

  // Each time anything changes
  jQuery(".rep-locator .form-element select").on("change", function() {
    if (jQuery(this).val() != "") {
      jQuery(this)
        .parent()
        .addClass("valid");
    } else {
      jQuery(this)
        .parent()
        .removeClass("valid");
    }
  });

  // When the market changes, check to see if country is set and check
  // Each time anything changes
  jQuery("#rep-finder-market").on("change", function() {
    if (jQuery(this).val() != "" && jQuery("#rep-finder-country").val() != "") {
      //rep_finder_status("processing");
      //check_for_rep();
    }
  });
});

// Try HTML5 geolocation.
function detect_user_location() {
  if (navigator.geolocation) {
    const geocoder = new google.maps.Geocoder();

    navigator.geolocation.getCurrentPosition(
      function(position) {
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        geocoder.geocode({ location: latlng }, function(results, status) {
          if (status === "OK") {
            if (results[0]) {
              console.log(results[0].formatted_address);
              console.log(results[0]);

              var user_location_data = {
                country: results[0].address_components[6].long_name,
                state: results[0].address_components[5].long_name,
                county: results[0].address_components[4].long_name
              };

              console.log(user_location_data);
            } else {
              window.alert("No results found");
            }
          } else {
            window.alert("Geocoder failed due to: " + status);
          }
        });
      },
      function() {
        handleLocationError(true);
      }
    );
  } else {
    // Browser doesn't support Geolocation
    handleLocationError(false);
  }
}

function handleLocationError(browserHasGeolocation) {
  //infoWindow.setPosition(pos);
  //infoWindow.setContent(browserHasGeolocation ?
  //                      'Error: The Geolocation service failed.' :
  //                     'Error: Your browser doesn\'t support geolocation.');
  //infoWindow.open(map);
  console.log(browserHasGeolocation);
}

function rep_finder_status(status) {
  if (status == "processing") {
    jQuery(".rep-call-to-action").addClass("processing");
  } else {
    jQuery(".rep-call-to-action").removeClass("processing");
  }
}

jQuery(document).ready(function() {
  if (jQuery(".rep-locator").length > 0) {
    /*
     * When the country changes, update the regions.
     */
    jQuery("#rep-finder-country").on("change", function() {
      rep_finder_status("processing");

      jQuery("#rep-search-results .results-container").html(
        "<img src='" +
          vars.theme_url +
          "/dist/images/loading.gif' class='loading-reps'/>"
      );
      // Hide the form
      jQuery("#rep-form").removeClass("inplace");
      jQuery("#rep-contact-info").html("");

      jQuery.ajax({
        type: "post",
        dataType: "json",
        url: vars.ajaxurl,
        data: {
          action: "lab_get_regions",
          country: jQuery("#rep-finder-country").val()
        },
        success: function(response) {
          if (response.regions.length > 0) {
            // Clear regions
            jQuery("#rep-finder-region").html("");
            jQuery("#rep-finder-region").append(
              jQuery("<option/>", {
                value: "",
                text: "Please select..."
              })
            );
            // Loop through the regions and add them.
            for (var i = 0; i < response.regions.length; i++) {
              var region = response.regions[i];
              // Add region
              jQuery("#rep-finder-region").append(
                jQuery("<option/>", {
                  value: region.state_id,
                  text: region.name
                })
              );
            }
            // Now show the block if it's hidden.
            jQuery(".regions").addClass("inplace");
            rep_finder_status("done");
            jQuery("#rep-finder-region").addClass("required");
            // Make sure regions are now not valid
            jQuery(".regions").removeClass("valid");
            check_values();
          } else {
            jQuery("#rep-finder-region").removeClass("required");
            // Make sure the regions block is hidden.
            rep_finder_status("done");
            jQuery(".regions").removeClass("inplace");
            // There are no regions for this country, so just run a search
            check_values();
          }
        },
        error: function(errorThrown) {
          console.log("ERROR: " + errorThrown);
          rep_finder_status("done");
        }
      });
    });
    /*
     * When the region changes, update the counties.
     */
    jQuery("#rep-finder-region").on("change", function() {
      rep_finder_status("processing");
      jQuery.ajax({
        type: "post",
        dataType: "json",
        url: vars.ajaxurl,
        data: {
          action: "lab_get_counties",
          region: jQuery("#rep-finder-region").val()
        },
        success: function(response) {
          if (response.counties.length > 0) {
            // Clear counties
            jQuery("#rep-finder-county").html("");
            jQuery("#rep-finder-county").append(
              jQuery("<option/>", {
                value: "",
                text: "Please select..."
              })
            );
            // Loop through the regions and add them.
            for (var i = 0; i < response.counties.length; i++) {
              var county = response.counties[i];
              jQuery("#rep-finder-county").append(
                jQuery("<option/>", {
                  value: county.id,
                  text: county.name
                })
              );
            }
            // Now show the block if it's hidden.
            jQuery(".counties").addClass("inplace");
            rep_finder_status("done");
            jQuery("#rep-finder-county").addClass("required");
            // Make sure counties are now not valid
            jQuery(".counties").removeClass("valid");
            check_values();
          } else {
            rep_finder_status("done");
            // Make sure the counties block is hidden.
            jQuery(".counties").removeClass("inplace");
            jQuery("#rep-finder-county").removeClass("required");
            // There are no counties for this region, so just run a search
            check_values();
          }
        },
        error: function(errorThrown) {
          console.log("ERROR: " + errorThrown);
          rep_finder_status("done");
        }
      });
    });

    /*
     * When the county changes, run a search
     */
    jQuery("#rep-finder-county").on("change", function() {
      //rep_finder_status("processing");
      check_values();
    });
    /*
     * When market changes, run a search
     */
    jQuery("#rep-finder-market").on("change", function() {
      //rep_finder_status("processing");
      check_values();
    });
    /*
     * If there is a category selector we want to update our hidden category field.
     */
    jQuery("#rep-finder-category").on("change", function() {
      check_values();
    });
  }
});

// Copy the select dropdown categories over to the hidden field.
function parse_product_cats() {
  if (jQuery("#rep-finder-category").length > 0) {
    jQuery("#rep-finder-categories").val(jQuery("#rep-finder-category").val());
  }
}

function check_for_rep() {
  parse_product_cats();
  var product_categories = jQuery("#rep-finder-categories").val();
  var market = jQuery("#rep-finder-market").val();
  var country = jQuery("#rep-finder-country").val();
  var region = "";
  if (jQuery("#rep-finder-region").length > 0) {
    region = jQuery("#rep-finder-region").val();
  }
  var county = "";
  if (jQuery("#rep-finder-county").length > 0) {
    county = jQuery("#rep-finder-county").val();
  }

  var data = {
    action: "lab_search_reps",
    product_categories: product_categories,
    market: market,
    country: country,
    region: region,
    county: county
  };

  console.log(data);

  /*
   * Check to see if we have any reps
   */
  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "lab_search_reps",
      product_categories: product_categories,
      market: market,
      country: country,
      region: region,
      county: county
    },
    success: function(response) {
      rep_finder_status("done");
      // We found a rep, so show them
      console.log(response.rep);
      console.log("Length");
      console.log(response.rep.length);

      if (response.rep.is_fulton_rep) {
        html = response.rep.contact_info;
        jQuery("#rep-search-results .results-container").html(html);
        jQuery("#rep-search-results").removeClass("no-results");
        // Show the form
        jQuery("#rep-form").addClass("inplace");
      } else if (Object.keys(response.rep).length > 0) {
        html = "";
        const keys = Object.keys(response.rep);
        for (var i = 0; i < keys.length; i++) {
          var this_rep = response.rep[keys[i]];
          console.log("The rep:");
          console.log(this_rep);
          var address_html = "";
          var display_name;
          if (this_rep.fields.organization != "") {
            display_name = this_rep.fields.organization;
          } else {
            display_name = this_rep.display_name;
          }
          html += "<h4>" + display_name + "</h4>";

          // Address
          address_html += this_rep.fields.address_line_1 + "<br/>";
          if (this_rep.fields.address_line_2 != "") {
            address_html += this_rep.fields.address_line_2 + "<br/>";
          }
          address_html += this_rep.fields.city;
          if (this_rep.fields.state != "") {
            address_html += ", " + this_rep.fields.state;
          }
          if (this_rep.fields.zip != "") {
            address_html += " " + this_rep.fields.zip;
          }
          address_html += "<br/>" + this_rep.fields.country;

          //html +=
          //  '<br/><br/><button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#rep-form" aria-expanded="false" aria-controls="rep-form">Contact sales representative</button>';

          // Rep contact info
          var contact_info_html = "";

          if (this_rep.fields.phone != "") {
            contact_info_html +=
              "<div>Phone: " + this_rep.fields.phone + "</div>";
          }
          if (this_rep.fields.fax != "") {
            contact_info_html += "<div>Fax: " + this_rep.fields.fax + "</div>";
          }

          html += address_html;
          html += contact_info_html;

          jQuery("#rep-contact-info").html(contact_info_html);

          // Set the hidden form field
          if (this_rep.user_id > 0) {
            jQuery("#rep_user_id").val(this_rep.user_id);
          }
        }

        jQuery("#rep-search-results .results-container").html(html);
        jQuery("#rep-search-results").removeClass("no-results");
        // Show the form
        jQuery("#rep-form").addClass("inplace");
      } else {
        jQuery("#rep-search-results").removeClass("no-results");
      }
    },
    error: function(errorThrown) {
      console.log("ERROR: " + errorThrown);
      rep_finder_status("done");
    }
  });
}

function check_values() {
  var form_complete = true;

  console.log("Checking values");
  // If they don't have the required information filled out, remove any rep and reset
  jQuery(".rep-locator select.required").each(function() {
    if (jQuery(this).val() == "") {
      form_complete = false;
      console.log("Element has no value: " + jQuery(this).attr("id"));
    }
  });

  // Hide the form
  jQuery("#rep-form").removeClass("inplace");
  jQuery("#rep-contact-info").html("");

  // If the form is complete, lookup some results
  if (form_complete) {
    jQuery("#rep-search-results .results-container").html(
      "<img src='" +
        vars.theme_url +
        "/dist/images/loading.gif' class='loading-reps'/>"
    );

    check_for_rep();
  } else {
    // Reset sales rep results
    reset_rep_results();
  }
}

function reset_rep_results() {
  jQuery("#rep-search-results").addClass("no-results");
  html = "";
  html += "To find a sales rep in your area, please complete the form.";
  jQuery("#rep-search-results .results-container").html(html);
  // Hide the form
  jQuery("#rep-form").removeClass("inplace");
  jQuery("#rep-contact-info").html("");
}
